<template lang="pug">
CanvasPreloader(
  text="Logging out"
)
</template>

<script>
import Cookies from 'js-cookie';
import CanvasPreloader from '@/components/ProjectCanvas/PageOverlay/CanvasPreloader.vue';


export default {
  name: 'Logout',
  components: {
    CanvasPreloader,
  },
  mounted() {
    // clear out browser cookies
    Cookies.remove('oa_tkn');
    Cookies.remove('offeo_tmp_user_id');
    const isLiteMode = typeof this.$route.query !== 'undefined'
      && typeof this.$route.query.mode !== 'undefined'
      && this.$route.query.mode === 'lite';
    window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}/logout${isLiteMode ? '?mode=lite' : ''}`;
  },
};
</script>
